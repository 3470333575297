// eslint-disable-next-line filenames/match-exported
import {ThemeProvider} from '@material-ui/core/styles';

// import HomePage from 'src/views/Home';
import {MINUTE} from 'src/core/utils';
import {themeNext} from 'src/themes/themeNext';
import {prepareInitialProps} from 'src/core/initialPropsSSP';
import {PageSlugs} from 'src/core/urls';
import {HomePageNext} from 'src/views/HomePageNext';

import {App} from 'src/components/App';

const IndexPage = props => {
  // eslint-disable-next-line no-undef
  // if (process.env.ORIGIN === 'https://probemas.com') {
  //   return (
  //     <App {...props}>
  //       <HomePage products={props.products} reviews={props.reviews} />
  //     </App>
  //   );
  // }

  return (
    <App {...props}>
      <ThemeProvider theme={themeNext}>
        <HomePageNext
          products={props.products}
          reviews={props.reviews}
          testimonials={props.testimonials}
        />
      </ThemeProvider>
    </App>
  );
};

export async function getStaticProps(ctx) {
  const pageProps = await prepareInitialProps(ctx, {
    slug: PageSlugs.INDEX,
    loadReviews: true,
    loadTestimonials: true
  });
  return {
    revalidate: MINUTE,
    props: {
      ...pageProps
    }
  };
}

export default IndexPage;
