import {makeStyles} from '@material-ui/core/styles';
import DOMPurify from 'isomorphic-dompurify';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {extendedTypography} from 'src/themes/themeNext/extendedTypography';
import {BuyGoldCards} from 'src/componentsNext/GoldCards/BuyGoldCards';
import useStorefrontData from 'src/hooks/useStorefrontData';

const useStyles = makeStyles(theme => ({
  topSectionRoot: {
    paddingTop: theme.spacing(10),
    margin: '0 auto',

    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(9),
      marginBottom: -theme.spacing(1.5)
    }
  },
  bannerSection: {
    maxWidth: 1300 + theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: [theme.spacing(0, 'auto')],
    padding: theme.spacing(4, 2, 3.5, 2),

    [theme.breakpoints.down('xs')]: {
      flexWrap: 'wrap',
      padding: theme.spacing(2, 2, 0, 2)
    }
  },
  title: {
    ...extendedTypography.titleDesktop,
    color: theme.palette.common.white,
    textAlign: 'center',
    textTransform: 'uppercase',

    [theme.breakpoints.down('sm')]: {
      ...extendedTypography.h1Mobile,
      fontSize: 36,

      '& span': {
        color: theme.palette.secondary.main
      }
    }
  },
  subtitle: {
    ...extendedTypography.h3Desktop,
    color: theme.palette.common.white,
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      ...extendedTypography.body1MediumMobile
    }
  },
  bannerContent: {
    // maxWidth: 630,
    display: 'grid',
    // gap: theme.spacing(1.5),
    justifyItems: 'center',
    marginBottom: theme.spacing(9),

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyItems: 'normal',
      marginBottom: theme.spacing(2.5)
    }
  },
  secondaryText: {color: theme.palette.secondary.main}
}));

export const TopSection = ({products}) => {
  const classes = useStyles();
  const {currency, page} = useStorefrontData();
  const isDownSm = useMediaQuery(theme => theme.breakpoints.down('sm'));

  return (
    <div className={classes.topSectionRoot}>
      <div className={classes.bannerSection}>
        <div className={classes.bannerContent}>
          <Typography
            classes={{root: clsx(classes.title, classes.secondaryText)}}
            dangerouslySetInnerHTML={{
              __html: page && DOMPurify.sanitize(page.title)
            }}
          />

          <Typography
            classes={{root: classes.subtitle}}
            dangerouslySetInnerHTML={{
              __html: page && DOMPurify.sanitize(page.subtitle)
            }}
          />
        </div>
        <BuyGoldCards products={products} currency={currency} />
      </div>
    </div>
  );
};
